var base_url = '/'; 

$(document).ready(function() {
    equalMenuWidth();
    initMagnificPopupGalleries($('.block-grid.use-gallery'));
});

$(window).load(function () {
//    equalheight('#categories_container .box');
});

$(window).resize(function () {
    equalMenuWidth();
//    equalheight('#categories_container .box');
});

equalheight = function (container) {
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;

    $(container).each(function () {
        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

equalMenuWidth = function () {
    var windowWidth = $(window).width();

    if(windowWidth > 768) {
        var $container = $('#Navbar'),
            $elements = $('#Navbar .nav > li.normal'),
            numberOfItems = $elements.length,
            // for evenly distributed li elements
            menuWidth = $container.outerWidth(),
            elementWidth = Math.round(menuWidth / numberOfItems).toFixed(2)-2;
        $elements.css('width', elementWidth);
    }
}

initMagnificPopupGalleries = function($galleries) {
    var count = $galleries.length;
    if(count > 0) {
        if(count > 1) {
            $galleries.each(function() {
                initMagnificPopup($(this));
            });
        } else {
            initMagnificPopup($galleries);
        }
    }
}

initMagnificPopup = function($gallery) {
    if($gallery.length) {
        $gallery.magnificPopup({
            delegate: 'a',
            type: 'image',
            mainClass: 'mfp-with-zoom',
            image: {
                titleSrc: 'title'
            },
            gallery: {
                enabled: true,
                preload: [0,1],
                navigateByImgClick: false
            },
            zoom: {
                enabled: true,
                duration: 300,
                easing: 'ease-in-out',
                opener: function(openerElement) {
                    return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
            }
        });
    }
}

function log(data) {
    if (console && console.log) {
        console.log(data);
    }
}