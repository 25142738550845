var base_url = '/'; 

$(document).ready(function() {
    showThirdMenuLevel();
    equalMenuWidth();
});

$(window).load(function () {
//    equalheight('#categories_container .box');
});

$(window).resize(function () {
    equalMenuWidth();
//    equalheight('#categories_container .box');
});

equalheight = function (container) {
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;

    $(container).each(function () {
        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

equalMenuWidth = function () {
    var windowWidth = $(window).width();

    if(windowWidth > 768) {
        var $container = $('#headerNavigation'),
            $elements = $('#headerNavigation > li'),
            numberOfItems = $elements.length,
            // for evenly distributed li elements
            menuWidth = $container.outerWidth(),
            elementWidth = Math.round(menuWidth / numberOfItems).toFixed(2);
        
        $elements.css('width', elementWidth);
    }
}

// for third level of menu
function showThirdMenuLevel() {
    var $element = $('.nav-bar .has-flyout .flyout .has-flyout');
    
    if($element.length) {
        $element.hover(function() {
            $(this).find('ul.flyout').show();
        }, function() {
            $(this).find('ul.flyout').hide();
        });
    }
}

function log(data) {
    if (console && console.log) {
        console.log(data);
    }
}